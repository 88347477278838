import { graphql, PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, Trans } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
import Base from '../components/base';
import SEO from '../components/seo';

const PageNotFound = ({ location }: PageProps<Queries.PageNotFoundQuery>) => {
  return (
    <Base location={location}>
      <div className="text-black relative mt-[5.625rem] lg:mt-0">
        <div className="flex aspect-[9/12] lg:aspect-video w-full justify-center items-center rounded-xl">
          <div className="absolute flex flex-col aspect-[9/16] lg:aspect-video w-full justify-center items-center pb-[10rem] 2xl:pb-[15rem] lg:pb-0 text-center gap-[0.5rem] 2xl:gap-[1.5rem]">
            <h1 className="text-[5.625rem] leading-[5.313rem]">404</h1>
            <h3>
              <Trans>Oops! You just got lost in Cyber Multiverse</Trans>
            </h3>
            <p>
              <Trans>Click back to safety</Trans>
            </p>
            <div className="bg-[url('../images/tag-arrow.svg')] bg-no-repeat bg-center p-[1.5rem] h-[3rem] animate-bounce" />
          </div>
          <div className="absolute flex aspect-[9/8] lg:aspect-video w-full justify-center items-end p-[5rem] lg:p-[4rem] 2xl:pb-[18rem]">
            <Link
              to="/"
              className="border-solid border-[0.125rem] p-[1rem] rounded-xl shadow-sm hover:drop-shadow-lg hover:opacity-90"
            >
              <StaticImage
                src="../images/CR14-logo-white.svg"
                height={30}
                alt="Link to Mainpage"
              />
            </Link>
          </div>
          <video
            className="rounded-xl absolute right-0 bottom-0 min-h-full min-w-full w-auto h-auto overflow-hidden bg-cover -z-10 object-cover"
            autoPlay
            muted
            loop
            playsInline
          >
            <source src="/videos/about.webm" type="video/webm; codecs=vp9" />
            <source src="/videos/about.mp4" type="video/mp4; codecs=hevc" />
            <source src="/videos/about.m4v" type="video/mp4" />
          </video>
        </div>
      </div>
    </Base>
  );
};

export default PageNotFound;

export const pageQuery = graphql`
  query PageNotFound($language: String!) {
    site {
      siteMetadata {
        title
      }
    }
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => <SEO />;
